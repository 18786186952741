<template>
  <v-container>
    <!-- TITULOS -->
    <div class="d-flex my-6">
      <h1 class="h4 semiBold">Cotizaciones y Ventas en proceso</h1>
      <v-chip
        class="ml-3"
        outlined
        close
        v-if="$route.query.rut"
        @click:close="$router.push({ name: 'quotes' }), setup()"
        >{{ $route.query.rut | rutFormat }}</v-chip
      >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        v-if="getUserInfo?.cliente"
        class="text-none"
        :to="{
          name: 'UserDetail',
          params: { rut: getUserInfo?.cliente?.numero_documento ?? '' },
        }"
        >Volver al detalle del cliente
        <v-icon right>mdi-arrow-right</v-icon></v-btn
      >
    </div>

    <!-- FILTROS -->
    <v-row>
      <v-col>
        <v-card class="bordered px-4" elevation="1">
          <v-card-text
            :class="{
              'd-flex justify-space-between align-center':
                $vuetify.breakpoint.smAndUp,
            }"
          >
            <v-text-field
              class="mx-1"
              v-model="textSearch.value"
              :label="textSearch.label"
              :placeholder="textSearch.placeholder"
              persistent-placeholder
              disabled
              outlined
              hide-details
              dense
              :prepend-icon="textSearch.icon"
            ></v-text-field>

            <v-select
              class="mx-1"
              :items="plazoField.options"
              v-model="plazoField.value"
              :label="plazoField.label"
              :placeholder="plazoField.placeholder"
              outlined
              hide-details
              dense
              persistent-placeholder
              disabled
            ></v-select>

            <v-select
              class="mx-1"
              :items="steps"
              v-model="estadoField.value"
              :label="estadoField.label"
              :placeholder="estadoField.placeholder"
              persistent-placeholder
              outlined
              dense
              hide-details
              clearable
            ></v-select>

            <v-btn
              class="ml-1"
              color="primary"
              height="40"
              @click="filter()"
              :disabled="loading"
              rounded
            >
              <v-icon left>mdi-filter</v-icon>
              Filtrar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- DATOS -->
    <v-data-table
      :headers="headers"
      :items="Cotizaciones"
      class="elevation-1 mt-3"
      :loading="loading"
      :page="pagination.page"
      :server-items-length="pagination.objects"
      no-data-text="No hay cotizaciones para mostrar"
      no-results-text="No se han encontrado cotizaciones"
      loading-text="Cargando cotizaciones..."
      :footer-props="{
        'items-per-page-text': 'Mostrar por página:',
        'items-per-page-options': [5, 10, 20, 30, 50, 100],
      }"
      @pagination="setPaginationFromTable($event)"
    >
      <template #item.fecha_creacion="{ item }">
        {{ item.fecha_creacion | formatDate }}
      </template>

      <template #item.numero_documento="{ item }">
        {{ item.numero_documento | rutFormat }}
      </template>

      <template #item.estado="{ item }">
        <v-chip small>
          {{
            steps.find((e) => e.value === item.estado)?.text ?? "Desconocido"
          }}
        </v-chip>
      </template>

      <template #item.plazo_retoma="{ item }">
        <v-badge
          left
          dot
          :color="getRetomaColor(getRetomaPlazoNumber(item.plazo_retoma))"
        >
        </v-badge>
        <span class="ml-1">
          {{ getRetomaPlazoNumber(item.plazo_retoma) }}
          Días
        </span>
      </template>

      <template #item.action="{ item }">
        <v-btn
          color="primary"
          text
          fab
          small
          @click="Retomar(item)"
          :disabled="loading"
          :loading="item.loading"
        >
          <v-icon>mdi-clipboard-arrow-right-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { resumeQuote } from "@/Mixins/resumeQuote.js";
import { PaginationMixin } from "@/Mixins/PaginationMixin.js";

export default {
  name: "ListadoDeCotizaciones",
  mixins: [resumeQuote, PaginationMixin],
  data() {
    return {
      textSearch: {
        placeholder: "Escriba un numero de cotización o RUT..",
        label: "Buscar por Nro de cotización o RUT",
        value: "",
        icon: "mdi-magnify",
      },
      loading: false,

      // TABLE HEADERS
      headers: [
        {
          text: "Nº Cotización",
          value: "numero_cotizacion",
          align: "left",
        },
        {
          text: "Fecha creación",
          value: "fecha_creacion",
          align: "left",
        },
        {
          text: "RUT Asegurado",
          align: "left",
          value: "numero_documento",
        },
        { text: "Asegurado", value: "cliente", align: "left" },
        {
          text: "Tipo de Seguro",
          value: "categoria_descripcion",
          align: "left",
        },
        {
          text: "Plazo de retoma",
          value: "plazo_retoma",
          align: "left",
        },
        { text: "Etapa", value: "estado", align: "left" },
        {
          text: "Completar",
          value: "action",
          align: "right",
          sortable: false,
        },
      ],
      // SelectField PLAZO
      plazoField: {
        label: "Buscar por plazo",
        options: [
          "Todos",
          "Menor a 5 Días",
          "Entre 6 a 10 Días",
          "Mayor a 10 Días",
        ],
        placeholder: "Seleccione un plazo",
        value: "",
      },
      // SelectField Estado
      estadoField: {
        label: "Estado",
        placeholder: "Seleccione un estado",
        value: "",
      },
    };
  },
  methods: {
    ...mapActions("Quot", ["GetCotizaciones"]),

    setup() {
      this.loading = true;
      let params = {
        limite: this.pagination.limit,
        pagina: this.pagination.page,
      };
      if (this.$route.query.rut) {
        params.rut = this.$route.query.rut;
      }
      if (this.estadoField.value) {
        params.estado = this.estadoField.value;
      }
      this.GetCotizaciones(params)
        .then((response) => {
          const pagination = response?.pagination;
          this.setOrResetPagination(pagination);
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },

    getRetomaPlazoNumber(retomaList) {
      return Math.max.apply(
        Math,
        retomaList.map(function (o) {
          return o.plazo_retoma;
        })
      );
    },
    getRetomaColor(n) {
      let num = n;
      let color = "";
      if (typeof n === "string") {
        num = parseInt(n);
      }
      if (num > 0 && num < 4) color = "#EE2E22";
      else if (num >= 4 && num < 7) color = "#F2C94C";
      else if (num >= 7) color = "#27AE60";
      else color = "primary";
      return color;
    },
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo"]),
    ...mapGetters("Quot", ["Cotizaciones"]),

    steps() {
      return [
        {
          text: "Falta Información",
          value: "FAL",
        },
        {
          text: "Abierta",
          value: "A",
        },
        {
          text: "Seleccionada",
          value: "SEL",
        },
      ];
    },
  },
};
</script>
