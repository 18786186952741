var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"d-flex my-6"},[_c('h1',{staticClass:"h4 semiBold"},[_vm._v("Cotizaciones y Ventas en proceso")]),(_vm.$route.query.rut)?_c('v-chip',{staticClass:"ml-3",attrs:{"outlined":"","close":""},on:{"click:close":function($event){_vm.$router.push({ name: 'quotes' }), _vm.setup()}}},[_vm._v(_vm._s(_vm._f("rutFormat")(_vm.$route.query.rut)))]):_vm._e(),_c('v-spacer'),(_vm.getUserInfo?.cliente)?_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","text":"","to":{
        name: 'UserDetail',
        params: { rut: _vm.getUserInfo?.cliente?.numero_documento ?? '' },
      }}},[_vm._v("Volver al detalle del cliente "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"bordered px-4",attrs:{"elevation":"1"}},[_c('v-card-text',{class:{
            'd-flex justify-space-between align-center':
              _vm.$vuetify.breakpoint.smAndUp,
          }},[_c('v-text-field',{staticClass:"mx-1",attrs:{"label":_vm.textSearch.label,"placeholder":_vm.textSearch.placeholder,"persistent-placeholder":"","disabled":"","outlined":"","hide-details":"","dense":"","prepend-icon":_vm.textSearch.icon},model:{value:(_vm.textSearch.value),callback:function ($$v) {_vm.$set(_vm.textSearch, "value", $$v)},expression:"textSearch.value"}}),_c('v-select',{staticClass:"mx-1",attrs:{"items":_vm.plazoField.options,"label":_vm.plazoField.label,"placeholder":_vm.plazoField.placeholder,"outlined":"","hide-details":"","dense":"","persistent-placeholder":"","disabled":""},model:{value:(_vm.plazoField.value),callback:function ($$v) {_vm.$set(_vm.plazoField, "value", $$v)},expression:"plazoField.value"}}),_c('v-select',{staticClass:"mx-1",attrs:{"items":_vm.steps,"label":_vm.estadoField.label,"placeholder":_vm.estadoField.placeholder,"persistent-placeholder":"","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.estadoField.value),callback:function ($$v) {_vm.$set(_vm.estadoField, "value", $$v)},expression:"estadoField.value"}}),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","height":"40","disabled":_vm.loading,"rounded":""},on:{"click":function($event){return _vm.filter()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"headers":_vm.headers,"items":_vm.Cotizaciones,"loading":_vm.loading,"page":_vm.pagination.page,"server-items-length":_vm.pagination.objects,"no-data-text":"No hay cotizaciones para mostrar","no-results-text":"No se han encontrado cotizaciones","loading-text":"Cargando cotizaciones...","footer-props":{
      'items-per-page-text': 'Mostrar por página:',
      'items-per-page-options': [5, 10, 20, 30, 50, 100],
    }},on:{"pagination":function($event){return _vm.setPaginationFromTable($event)}},scopedSlots:_vm._u([{key:"item.fecha_creacion",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.fecha_creacion))+" ")]}},{key:"item.numero_documento",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("rutFormat")(item.numero_documento))+" ")]}},{key:"item.estado",fn:function({ item }){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.steps.find((e) => e.value === item.estado)?.text ?? "Desconocido")+" ")])]}},{key:"item.plazo_retoma",fn:function({ item }){return [_c('v-badge',{attrs:{"left":"","dot":"","color":_vm.getRetomaColor(_vm.getRetomaPlazoNumber(item.plazo_retoma))}}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.getRetomaPlazoNumber(item.plazo_retoma))+" Días ")])]}},{key:"item.action",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary","text":"","fab":"","small":"","disabled":_vm.loading,"loading":item.loading},on:{"click":function($event){return _vm.Retomar(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-arrow-right-outline")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }